<template>
  <v-row justify="center">
    <v-dialog v-model="dialogLocal" max-width="350px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Are you sure?</span>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" class="text-center">
                    <v-btn color="orange" fab depressed large disabled>
                        <v-icon large color="orange">fas fa-exclamation</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="text-center">
                    <h2 v-if="modal_action_type == 'reset' ||
                        modal_action_type == 'sync' ||
                        modal_action_type == 'sync cp delta' ||
                        modal_action_type == 'aPassback' || modal_action_type == 'exitlpr'
                        ">You want to trigger this action?
                    </h2>
                    <h2 v-else>You want to {{modal_action_type == 'createfinal' ? "create" : modal_action_type}} this?</h2>
                    <small v-if="modal_action_type != 'create'">This action cannot be undone</small>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn small dark color="primary" @click="$emit('confirm', true)">Yes</v-btn>
          <v-btn small dark color="red darken-1" @click="$emit('closed', false)">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['dialog', 'modalInfo', 'modal_action_type', 'customText'],

    data() {
        return {
            dialogLocal: false
        }
    },

    

    watch: {
        dialog: {
        handler: function(value) {
            this.modalHandler();
        },
        },

        dialogLocal: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        modalHandler(){
            this.dialogLocal = !this.dialogLocal;
        },

        modalCloseHandler(){
            if (this.dialogLocal == false)
            this.$emit('closed', false);
        },

        Unix_timestamp(t) {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp*1000);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == null){
                var convdataTime = '';
            }

            return convdataTime;
    }
    }
  }
</script>